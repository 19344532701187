@use "../../Config/variables.scss" as vars;

.destinations-component {
  .destination-popular-image {
    position: relative;
    overflow: hidden;
  }

  .destination-popular-image img {
    height: 220px;
    width: 100%;
    display: block;
    transition: transform 0.3s ease;
    object-fit: cover;
  }

  .destination-popular-image img:hover {
    transform: scale(1.1);
  }
}
