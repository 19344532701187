.booking {
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0.65),
    ),
    url("../../assets/img/others/book_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
