@use "../../Config/variables.scss" as vars;

.ship-details {
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  transition: background-image 5s ease-in-out !important;
  .ships-intro-common {
    max-height: calc(100vh - 275px);
    overflow: hidden;
  }
  .ship-image-large {
    background-size: contain;
    width: 100%;
  }
  .ship-info-container {
    max-height: 220px;
    overflow: hidden;
    padding: 25px;
    text-align: center;
  }
  .info-icon {
    height: 60px;
    margin-bottom: 16px;
  }

  .ship-menu-custom-divider {
    background-color: vars.$primary;
    height: 3px;
    width: 30px;
  }
  .custom-right-button {
    right: 10px;
  }

  .custom-left-button,
  .custom-right-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 500;
    background-color: transparent;
  }

  .custom-left-button {
    left: 10px;
  }

  .custom-right-button {
    right: 10px;
  }
  .details-text {
    font-size: 18px;
  }
  @media screen and (max-width: 992px) {
    .ships-intro-common {
      max-height: calc(100vh - 355px);
    }
    .ship-info-container {
      height: 300px;
      padding: 15px;
    }
    .info-icon {
      height: 20px;
      margin-bottom: 8px;
    }
    .details-text {
      font-size: 14px;
    }
    .scroll-more-btn {
      display: none;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .responsive-header-height {
      height: 7vh;
    }
  }
}
