@use "../../Config/variables.scss" as vars;
.contact {
  background-image: linear-gradient(
      to bottom,
      rgba(93, 92, 92, 0.696),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../assets/img/others/contact_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: rgb(248, 247, 243);
  min-height: 100vh;
  padding-bottom: 32px;
  .contact-icons {
    color: vars.$primary;
    font-size: 16px;
    margin: 0px 2px 2px 0px;
  }
  .contact-form-card {
    background-color: vars.$white;
    padding: 64px 24px;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  }
  .contact-left-col{
    background-color: vars.$primary;
    padding: 64px 24px;
  }
  .responsive-header-height {
    height: 14vh;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .responsive-header-height {
      height: 7vh;
    }
  }
}
