@use "../../Config/variables.scss" as vars;
.ship {
  .ship-card {
    height: 250px;
    cursor: pointer;
  }
  .slider-child-ships{
    margin-right: 8px;
  }
  .ship-info-div {
    background-color: #0071a65c;
  }

  .ship-img-div {
    background-size: contain;
    height: 100%;
    margin-right: 6px;
    margin-left: 6px;
    background-repeat: no-repeat;
  }

  .custom-next-button-cmp,
  .custom-prev-button-cmp {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    z-index: 999;
    background-color: vars.$white;
  }

  .custom-next-button-cmp,
  .custom-prev-button-cmp:hover {
    background-color: vars.$white !important;
  }

  .custom-prev-button-cmp {
    left: 0;
    margin-left: 15px;
    width: 20px;
  }

  .custom-next-button-cmp {
    right: 0;
    margin-right: 20px;
    width: 20px;
  }
  .ships-custom-divider {
    background-color: rgba(255, 255, 255, 0.493);
    height: 2px;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .slider-child-ships{
      margin-right: 0px;
    }
    .custom-next-button-cmp,
    .custom-prev-button-cmp {
      position: absolute;
      top: 95%;
      transform: translateY(-50%);
      z-index: 999;
      background-color: vars.$white;
    }
    .custom-prev-button-cmp {
      left: 0;
      margin-left: 130px;
      width: 20px;
    }
  
    .custom-next-button-cmp {
      right: 0;
      margin-right: 130px;
      width: 20px;
    }
  }
}
