@use "../../Config/variables.scss" as vars;
.footer {
  background-color: vars.$primary;
  // background-image: linear-gradient(to bottom, #0053a0cc, #0054a0),
  //   url("../../assets/img/others/footerbg.jpg");
  padding: 24px 0px 12px 0px;
  .footer-logo img {
    height: 30px;
    margin-left: -2px;
    margin-bottom: 30px;
  }
  .footer-divider {
    background-color: vars.$white !important;
    margin: 16px 0px 12px 0px;
  }
  .footer-link {
    font-size: 16px;
    font-family: vars.$JostRegular;
    // font-size: 17.6px;
  }
  .footer-heading {
    font-size: 17.6px;
    font-family: vars.$JostRegular;
  }
  .footer-link:hover {
    color: vars.$white;
  }
  .footer-copyright-div1 {
    align-items: center;
    justify-content: flex-start;
  }
  .footer-copyright-div2 {
    align-items: center;
    justify-content: flex-end;
  }
  .footer-cols1  {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer-cols2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-cols3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    .footer-heading {
      margin-top: 30px;
    }
    .footer-cols1,.footer-cols2,.footer-cols3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footer-logo img {
      margin-left: -70px;
      margin-bottom: 0px;
    }
    .footer-copyright-div1 {
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 8px;
    }
    .footer-copyright-div2 {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
