@use "./Config/variables.scss" as vars;

.layout-container {
  min-height: 100vh;

  .opacity80{
    opacity: 0.8;
  }

  .general-shadow {
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1);
  }
  .breadcrumb-div {
    background-color: rgba(255, 255, 255, 0.726);
    height: 60px !important;
  }

  .card-button {
    font-size: 14px;
    border-radius: 6px;
    height: 37px;
    font-weight: 500;
    border: none;
    box-shadow: none;
  }
  .btn-primary:hover {
    background-color: vars.$primary !important;
    color: vars.$white !important;
  }
  .btn-white:hover {
    background-color: vars.$white !important;
    color: vars.$black !important;
  }

  .btn-small {
    height: 35px;
  }

  .btn-large {
    width: 230px;
    height: 50px;
  }

  .btn-wide {
    height: 40px;
  }

  .custom-carousel-container {
    position: relative;
  }

  .custom-next-button,
  .custom-prev-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    background-color: vars.$white;
  }
  .custom-next-button,
  .custom-prev-button:hover {
    background-color: vars.$white !important;
  }

  .custom-prev-button {
    left: 0;
    margin-left: 45px;
  }

  .custom-next-button {
    right: 0;
    margin-right: 45px;
  }

  input {
    font-size: 16px !important;
    font-weight: 500;
  }

  .ant-picker-input input {
    font-size: 16px !important;
    font-weight: 500;
  }

  .intro-common {
    min-height: calc(100vh - 50px);
  }

  .intro-common-small {
    min-height: 280px;
  }

  .full-height {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .pointer {
    cursor: pointer;
  }

  .capitalize {
    text-transform: uppercase;
  }

  .justified {
    text-align: justify;
  }

  .letter-spacing {
    letter-spacing: 1px;
  }

  .common-header-divider {
    height: 7px;
    background-color: #f1f2f6;
    width: 5%;
  }

  .text-align-center {
    text-align: center;
  }

  .book-now-img img {
    height: 400px;
  }

  .no-radius {
    border-radius: 0px !important;
  }

  .no-decoration {
    text-decoration: none;
  }

  //flex css start
  .flex {
    display: flex;
  }

  .center {
    align-items: center;
    justify-content: center;
  }

  .end {
    align-items: center;
    justify-content: flex-end;
  }

  .start {
    align-items: center;
    justify-content: flex-start;
  }

  .start-center {
    justify-content: center;
    align-items: flex-start;
  }

  .center-end {
    justify-content: flex-end;
    align-items: center;
  }

  .center-start {
    justify-content: flex-start;
    align-items: center;
  }

  .top-end {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .bottom-end {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .bottom-start {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .column-bottom-start {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .space-between {
    justify-content: space-between;
  }

  //flex css end
  //bg color start
  .bg-white {
    background-color: vars.$white;
  }

  .bg-offwhite {
    background-color: vars.$off-white;
  }

  .secondary-bg {
    background-color: vars.$secondary;
  }

  .bg-black {
    background-color: vars.$black;
  }

  .bg-primary {
    background-color: vars.$primary !important;
  }

  //bg color end
  //font color start
  .font-white {
    color: vars.$white;
  }
  .font-offwhite {
    color: vars.$off-white;
  }

  .font-black {
    color: vars.$black;
  }

  .font-black-header {
    color: rgba(0, 0, 0, 0.836);
  }

  .font-primary {
    color: vars.$primary;
  }

  .font-helper {
    color: vars.$helper;
  }

  .font-faded-black {
    color: vars.$faded-black;
  }

  .font-link {
    color: #d5f5fc;
  }

  //font color end
  //common margins start
  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mt-64 {
    margin-top: 64px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mb-42 {
    margin-bottom: 42px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mt-42 {
    margin-top: 42px;
  }

  .mt-48 {
    margin-top: 48px;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .ml-3 {
    margin-left: 3px;
  }

  .mr-8 {
    margin-right: 8px;
  }

  .mr-16 {
    margin-right: 16px;
  }

  .mv-32 {
    margin: 32px 0px;
  }

  .mh-16 {
    margin: 0px 16px;
  }

  .mh-8 {
    margin: 0px 8px;
  }

  //margin here onwards no rework
  .mth-48 {
    margin: 48px 48px 0px 48px;
  }

  .mbh-48 {
    margin: 0px 48px 48px 48px;
  }

  //common margins end
  //common padding start
  .column-padding {
    padding: 70px;
  }

  .column-padding-h {
    padding: 0px 70px;
  }

  .column-padding-t {
    padding-top: 70px;
  }

  .column-padding-b {
    padding-bottom: 70px;
  }

  .pt-48 {
    padding-top: 48px;
  }

  .pb-42 {
    padding-bottom: 42px;
  }

  .pb-16 {
    padding-bottom: 16px;
  }

  .pb-32 {
    padding-bottom: 32px;
  }

  .pt-32 {
    padding-top: 32px;
  }

  .pl-12 {
    padding-left: 12px;
  }

  .p-24 {
    padding: 24px;
  }

  .ph-24 {
    padding: 0px 24px;
  }

  .pv-32 {
    padding: 32px 0px;
  }

  .pv-64 {
    padding: 64px 0px;
  }

  .ph-0 {
    padding-right: 0px;
    padding-left: 0px;
  }

  //common padding end
  //font-size/family start
  .font-regular {
    font-family: vars.$JostRegular;
  }

  .font-medium {
    font-family: vars.$JostMedium;
  }

  .font-bold {
    font-family: vars.$JostBold;
  }

  .heading {
    font-size: 32px;
  }

  .large-heading {
    font-size: 48px;
  }

  .sub-heading {
    font-size: 28px;
  }

  .description-text {
    font-size: 20px;
  }

  .basic-texts {
    font-size: 16px;
  }

  .smaller-texts {
    font-size: 11px;
  }

  .font-14{
    font-size: 14px;
  }

  .opacity-80{
    opacity: 0.8;
  }

  //font size end
  //font weight start
  .bold {
    font-weight: bold;
  }

  .bold-800 {
    font-weight: 800;
  }

  .bold-700 {
    font-weight: 700;
  }

  .bold-600 {
    font-weight: 600;
  }

  .bold-500 {
    font-weight: 500;
  }

  .bold-400 {
    font-weight: 400;
  }

  //font weight end
  //img start
  .image-container {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer;
  }

  .image-container img {
    width: 100%;
    height: auto;
    max-height: 300px;
    display: block;
  }

  //img end
  //card start
  .sliding-card {
    height: 280px;
  }

  //card end
  //link start
  .white-link {
    text-decoration: none;
    color: vars.$white;
  }

  .black-link {
    text-decoration: none;
    color: vars.$black;
  }

  //link end
  //carousal
  //override start
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-typography-copy-success {
    color: vars.$success !important;
  }

  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }

  .image-gallery-svg {
    fill: #fff;
    /* Customize the arrow color */
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: block !important;
    /* Make arrows always visible */
  }

  .ant-drawer-close {
    display: none !important;
  }

  //override end
  //media query start
  @media screen and (max-width: 768px) {
    .sub-heading {
      font-size: 19px;
    }

    .heading {
      font-size: 23px;
    }

    .large-heading {
      font-size: 39px;
    }

    .btn-large {
      width: 200px;
      height: 40px;
    }

    .bold-700 {
      font-weight: 600;
    }

    .bold-500 {
      font-weight: 400;
    }

    .description-text {
      font-size: 15px;
    }

    .basic-texts {
      font-size: 12px;
    }

    .column-padding {
      padding: 10px;
    }

    .column-padding-h {
      padding: 0px 10px;
    }

    .column-padding-t {
      padding-top: 55px;
    }

    .column-padding-b {
      padding-bottom: 55px;
    }

    .custom-next-button,
    .custom-prev-button {
      display: none;
    }
    .custom-carousel-container{
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    .responsive-column-padding {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  //media query end
}
