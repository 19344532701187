@use "../../Config/variables.scss" as vars;
.registration {
  .input-div{
    max-width: 600px;
  }
  @media screen and (max-width: 768px) {
    .input-div{
      max-width: 100vw;
    }
  }
}
