$primary:#245F85;
$success:#33d9b2;
$helper:#63666a8c;
$white:#ffffff;
$off-white: #ececec;
$secondary:#cccccc3f;
$black:#000000;
$faded-black:rgba(0, 0, 0, 0.53);
$JostRegular:"JostRegular";
$JostMedium:"JostMedium";
$JostBold:"JostBold";