@use "../../Config/variables.scss" as vars;
.home {
  .main {
    width: 100%;
    height: calc(100dvh - 55px);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(to bottom, #0108107d 70%, #010810);
    height: calc(100dvh);
  }
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #333; /* Adjust background color as needed */
    padding: 10px;
    z-index: 100; /* Ensure the sticky header is above other content */
  }
  .search-area {
    background-color: vars.$white;
    width: 95vw;
    border-radius: 50px;
    margin-top: -30px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .date-picker-div {
    background-color: vars.$white;
    padding: 6px;
    margin-bottom: 12px;
    height: 82.63px;
  }
  .date-picker-div-small {
    background-color: vars.$white;
    margin-bottom: 12px;
    padding: 6px;
    width: 100%;
  }
  .custom-date-picker {
    border-radius: 0px !important;
    width: 100% !important;
  }
  .custom-auto-complete {
    width: 100% !important;
    // font-size: 16px !important;
  }

  .search-button {
    border-radius: 0px !important;
    height: 82.63px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-size: 16px;
  }

  .plan-button {
    margin-top: 12px;
    font-size: 14px;
    border-radius: 50px;
    width: 100%;
    height: 37px;
    // background: linear-gradient(to right, #46ddf8, #1d8df5);
    background-color: vars.$primary;
    font-weight: 500;
    color: #fff;
    border: none;
    box-shadow: none;
  }
  .search-button-small {
    border-radius: 0px !important;
    height: 45px;
    width: 100%;
    margin: 12px 0px 24px 0px;
    font-size: 16px;
  }
  .search-icon {
    font-size: 24px;
    margin-left: 6px;
    margin-bottom: 2px;
  }

  @media screen and (min-width: 0px) and (max-width: 991px) {
    .responsive-intro-form-large {
      display: none;
    }
    .plan-button {
      width: 80%;
    }
  }
  @media screen and (min-width: 992px) {
    .responsive-intro-form-small {
      display: none;
    }
  }
}
